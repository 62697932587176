import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Api from "~/util/Api";
import { random } from "~/util/Utils";
import { Loader } from "components";
import style from "./RemotePreview.module.scss";
import { useExperience } from "context/ExperienceContext";
import { Alert, Button, Space } from "@ogury/design-system";

const RemotePreview = ({ seed, onReloadAndSave, warningStoreChanged }) => {
  const [t] = useTranslation();
  const { experience } = useExperience();

  const [htmlData, setHtmlData] = useState(undefined);
  const [iframeKey, setIframeKey] = useState(seed);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const iframeRef = useRef(null);

  useEffect(() => {
    if (experience.id) {
      (async function () {
        try {
          setLoading(true);
          if (experience) {
            if (htmlData === undefined) {
              const data = await Api.getExperiencePreview(experience.id);
              setHtmlData(data);
            }
          } else {
            setHtmlData(undefined);
          }
        } catch (e) {
          console.warn(e);
          setError("error");
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [experience]);

  useEffect(() => {
    (async function () {
      if (htmlData !== undefined) {
        setLoading(true);
        try {
          const data = await Api.getExperiencePreview(experience.id);
          setHtmlData(data);
          iframeRef.current.contentWindow.document.documentElement.scrollTo(0, 0);
          setIframeKey(random());
        } catch (error) {
          console.warn(error);
          setError("error");
        } finally {
          setLoading(false);
        }
      } else {
        setIframeKey(seed);
      }
    })();
  }, [seed]);

  function renderLoading() {
    return <Loader overlay text={t("rightPanel.generatingPreview")} />;
  }

  function renderWarningStoreChanged() {
    return (
      <div className={style.errorContainer}>
        <Space direction="vertical" align="center">
          <Alert
            type="warning"
            closable={false}
            showIcon
            message={t("rightPanel.errors.remotePreview.warningStoreChangedTitle")}
            description={t("rightPanel.errors.remotePreview.warningStoreChanged")}
          />
          <Button type="secondary" onClick={onReloadAndSave}>
            {t("header.button.saveAndReload")}
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <>
      {loading && renderLoading()}
      {warningStoreChanged && renderWarningStoreChanged()}
      {htmlData !== undefined && (
        <iframe
          ref={iframeRef}
          key={iframeKey}
          srcDoc={htmlData}
          className={`${style.iframe} ${error || loading ? style.hidden : ""}`}
        />
      )}
    </>
  );
};

RemotePreview.propTypes = {
  seed: PropTypes.string.isRequired,
};

export default RemotePreview;
