import { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { fromPsdTransfer } from "~/util/Psd";
import { FilePickerV2 } from "components";
import { random } from "~/util/Utils";

const acceptFile = (file, fileExtensions, mimeTypes) => {
  const { type: mimeType } = file;
  {
    // We assess the file MIME type via the file extensions
    if (fileExtensions === "*") {
      return true;
    }
    const fileExtensionsTokens = typeof fileExtensions === "string" ? [fileExtensions] : fileExtensions;
    for (const fileExtensionsToken of fileExtensionsTokens) {
      if (mimeType === fileExtensionsToken || fileExtensionsToken.indexOf(mimeType) !== -1) {
        return true;
      }
      const index = fileExtensionsToken.indexOf("/*");
      if (index !== -1 && mimeType.startsWith(fileExtensionsToken.substring(0, index)) === true) {
        return true;
      }
    }
  }
  {
    // We assess the file MIME type via the MIME types
    if (mimeTypes === "*/*") {
      return true;
    }
    const mimeTypesTokens = typeof mimeTypes === "string" ? [mimeTypes] : mimeTypes;
    for (const mimeTypeToken of mimeTypesTokens) {
      if (mimeType === mimeTypeToken) {
        return true;
      }
      const index = mimeTypeToken.indexOf("/*");
      if (index !== -1 && mimeType.startsWith(mimeTypeToken.substring(0, index)) === true) {
        return true;
      }
    }
  }
  return false;
};

const FileInput = ({
  fileExtensions = "*",
  mimeTypes = "*/*",
  readDataUrl,
  readArrayBuffer,
  disabled,
  isButton = false,
  onProcessing,
  onError,
  onChanged,
  children,
  hint,
  "data-testid": dataTestId,
}) => {
  const fileInput = useRef();
  const localId = random(8);

  const handleFile = useCallback(
    async blob => {
      if (readDataUrl === true) {
        onProcessing();
        const reader = new FileReader();
        reader.onerror = error => {
          reader.onerror = null;
          reader.onload = null;
          onError(error);
        };
        reader.onload = event => {
          reader.onerror = null;
          reader.onload = null;
          const dataUrl = event.target.result;
          onChanged({
            blob: blob,
            fileName: blob.name,
            dataUrl: dataUrl,
          });
        };
        reader.readAsDataURL(blob);
      } else if (readArrayBuffer === true) {
        onProcessing();
        const reader = new FileReader();
        reader.onerror = error => {
          reader.onerror = null;
          reader.onload = null;
          onError(error);
        };
        reader.onload = event => {
          reader.onerror = null;
          reader.onload = null;
          if (event.target.readyState === FileReader.DONE) {
            const fileBytesArray = [];
            const arrayBuffer = event.target.result;
            const array = new Uint8Array(arrayBuffer);
            for (const a of array) {
              fileBytesArray.push(a);
            }
            onChanged({
              blob: blob,
              fileName: blob.name,
              fileBytesArray,
            });
          }
        };
        reader.readAsArrayBuffer(blob);
      } else {
        onChanged({ blob });
      }
    },
    [readDataUrl, onProcessing, onChanged, onError]
  );

  const onDrop = useCallback(
    async event => {
      event.preventDefault();
      if (disabled === true) {
        return;
      }

      let file;
      if (event.dataTransfer.items !== undefined) {
        // We use the "items" interface to access the files
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < event.dataTransfer.items.length; index++) {
          // If a dropped item is not a file, we reject it
          if (event.dataTransfer.items[index].kind === "file") {
            file = event.dataTransfer.items[index].getAsFile();
            break;
            // Drag n Drop PSD assets from PSD explorer, payload is a concatenation of : fileName ; data:image/png ; base64
          } else if (event.dataTransfer.items[index].kind === "string") {
            const { fileName, base64Data } = fromPsdTransfer(event.dataTransfer.getData("text"));
            const res = await fetch(base64Data);
            const blob = await res.blob();
            file = new File([blob], fileName);
            break;
          }
        }
      } else {
        // We use the "files" interface to access the files
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < event.dataTransfer.files.length; index++) {
          file = event.dataTransfer.files[index];
          break;
        }
      }

      if (file !== undefined) {
        if (acceptFile(file, fileExtensions, mimeTypes) === true) {
          await handleFile(file);
        }
      }
    },
    [disabled, handleFile]
  );

  const onChange = useCallback(
    async ({ target }) => {
      try {
        if (target.files.length >= 1) {
          await handleFile(target.files[0]);
        }
      } finally {
        // This forces the native component to reset the selected file, in case the same file is selected once again later on
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      }
    },
    [handleFile, fileInput]
  );

  return (
    <label htmlFor={localId} style={{ width: isButton === true ? "" : "100%" }} data-testid={dataTestId}>
      <div onDrop={onDrop}>
        {isButton === false && (
          // TODO: use Design system functionality. At the moment use file picker only for UI puropses, file preprocessing to complex to replace it altogether
          <FilePickerV2
            hint={hint}
            accept={fileExtensions && Array.isArray(fileExtensions) ? fileExtensions.join(",") : fileExtensions}
            fileList={[]}
            openFileDialogOnClick={false}
          />
        )}
        <input
          id={localId}
          ref={fileInput}
          type="file"
          style={{ display: "none" }}
          accept={fileExtensions && Array.isArray(fileExtensions) ? fileExtensions.join(",") : fileExtensions}
          disabled={disabled}
          onChange={onChange}
        />
        {isButton === true && children}
      </div>
    </label>
  );
};

FileInput.propTypes = {
  isButton: PropTypes.bool,
  fileExtensions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mimeTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  readDataUrl: PropTypes.bool,
  readArrayBuffer: PropTypes.bool,
  disabled: PropTypes.bool,
  onProcessing: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onChanged: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hint: PropTypes.string,
  "data-testid": PropTypes.string,
};

export default FileInput;
