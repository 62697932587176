import { createChildCollection, createModel } from "app/inputs/model/ModelFactory";
import References from "../util/References";
import { arrayMoveImmutable, deepCopy, isArray, isObject } from "~/util/Helpers";
import HistoryStore from "./HistoryStore";
import { BINARY_SUFFIX, META_SUFFIX } from "~/util/InputsComputer";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import Debug from "../util/Debug";
import unitService from "~/services/UnitService";

let DEEP_STORE = [];
let CURRENT_TEMPLATE = undefined;

function generateDeepStore(inputs, parentId, store = [], collectionsToAdd = []) {
  inputs.map(input => {
    const id = (parentId ? parentId + "." : "") + input.id;
    const instance = createModel(input, id);

    if (instance.isComposite()) {
      input.inputs = instance.computeChildInputs();
    }

    if (instance.isFinal()) {
      store.push({
        instance,
      });
    } else if (input.inputs) {
      let inputs = [];

      if (input[SCHEMA_INPUTS_FIELDS.Count] !== undefined) {
        store.push({
          inputs,
          instance,
        });
        if (input[SCHEMA_INPUTS_FIELDS.Count][SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] >= 0) {
          collectionsToAdd.push({
            id,
            count: input[SCHEMA_INPUTS_FIELDS.Count][SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum],
          });
        }
      } else {
        store.push({
          inputs,
          instance,
        });
        return generateDeepStore(
          input.inputs,
          id,
          store.find(treeNode => treeNode.instance.getId() === id).inputs,
          collectionsToAdd
        );
      }
    }
  });
  return { store, collectionsToAdd };
}

function initializeDeepStore() {
  if (CURRENT_TEMPLATE) {
    Debug.log("Initializing deep store with template '" + CURRENT_TEMPLATE.name + "'");
    const rootItems = [
      {
        id: References.ROOT_INPUT_ID,
        ui: {
          name: "Root group",
          label: "Root group containing all the inputs",
        },
        inputs: CURRENT_TEMPLATE.inputs,
      },
    ];

    const deepStore = generateDeepStore(rootItems);
    DEEP_STORE = deepStore.store;

    if (deepStore.collectionsToAdd.length >= 1) {
      deepStore.collectionsToAdd.forEach(collection => {
        for (let i = 0; i < collection.count; i++) {
          addCollection(collection.id, false);
        }
      });
    }
  }
}

function generateCollectionDeepStore(collectionInputs, collectionId) {
  return generateDeepStore(collectionInputs, collectionId);
}

/**
 *
 * @param nodeId
 * @param instanceOnly
 * This param defines if the children inputs are going to be returned or not. If instanceOnly is set to "false" (default) then, the whole children inputs nodes will be returned
 * @returns {undefined}
 */
function getDeepNodeById(nodeId, instanceOnly = false) {
  let node = undefined;
  if (DEEP_STORE.length >= 1) {
    function iterateThroughStore(nodes) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].instance.getId() === nodeId) {
          if (instanceOnly) {
            node = nodes[i].instance;
          } else {
            node = nodes[i];
          }
          break;
        }
        if (nodes[i].inputs) {
          iterateThroughStore(nodes[i].inputs);
        }
      }
    }

    iterateThroughStore(DEEP_STORE);
    return node;
  }
  return undefined;
}

function resetCollectionIds(collectionParentNode) {
  if (!collectionParentNode?.inputs) {
    return;
  }
  for (let i = 0; i < collectionParentNode.inputs.length; i++) {
    const baseId = collectionParentNode.instance.getId();
    const newBaseId = `${baseId}.${i}`;

    const instance = collectionParentNode.inputs[i].instance;
    instance.id = updateInstanceId(instance.id, baseId, newBaseId);
    updateNestedIds(collectionParentNode.inputs[i], baseId, newBaseId);
  }

  function updateNestedIds(node, baseId, newBaseId) {
    if (!node?.inputs) {
      return;
    }

    for (let i = 0; i < node.inputs.length; i++) {
      const instance = node.inputs[i].instance;
      instance.id = updateInstanceId(instance.id, baseId, newBaseId);
      updateNestedIds(node.inputs[i], baseId, newBaseId);
    }
  }

  function updateInstanceId(instanceId, baseId, newBaseId) {
    return instanceId.replace(new RegExp(`^${baseId}\\.\\d{1,7}`), newBaseId);
  }
}

function deleteCollection(collectionId, withHistory = true) {
  const collectionChildInstance = getDeepNodeById(collectionId, true);
  let collectionParentNode = collectionChildInstance
    ? getDeepNodeById(collectionChildInstance.getParentId())
    : undefined;
  let previousState;
  if (withHistory) {
    previousState = deepCopy(collectionParentNode);
  }
  collectionParentNode.inputs = collectionParentNode
    ? collectionParentNode.inputs.filter(childNode => childNode.instance.getId() !== collectionId)
    : undefined;
  resetCollectionIds(collectionParentNode);
  if (withHistory) {
    let currentState = deepCopy(collectionParentNode);
    HistoryStore.pushCollection(previousState, currentState);
  }
}

function moveCollection(collectionId, moveDown, withHistory = true) {
  const collectionChildInstance = getDeepNodeById(collectionId, true);
  let collectionParentNode = collectionChildInstance
    ? getDeepNodeById(collectionChildInstance.getParentId())
    : undefined;

  if (!collectionParentNode) {
    return;
  }

  let previousState;
  if (withHistory) {
    previousState = deepCopy(collectionParentNode);
  }
  const collectionChildOldIndex = collectionParentNode.inputs.findIndex(
    childNode => childNode.instance.getId() === collectionId
  );
  const collectionChildNewIndex = moveDown ? collectionChildOldIndex + 1 : collectionChildOldIndex - 1;

  collectionParentNode.inputs = arrayMoveImmutable(
    collectionParentNode.inputs,
    collectionChildOldIndex,
    collectionChildNewIndex
  );
  resetCollectionIds(collectionParentNode);
  if (withHistory) {
    let currentState = deepCopy(collectionParentNode);
    HistoryStore.pushCollection(previousState, currentState);
  }
  return collectionChildInstance;
}

function duplicateCollection(collectionId, withHistory = true) {
  let previousState;

  const collectionChildNode = getDeepNodeById(collectionId);
  const collectionParentNode = collectionChildNode
    ? getDeepNodeById(collectionChildNode.instance.getParentId())
    : undefined;
  if (withHistory) {
    previousState = deepCopy(collectionParentNode);
  }
  const parentCollectionsLength = collectionParentNode ? collectionParentNode.inputs.length : 0;

  const duplicatedCollectionNode = deepCopy(collectionChildNode);
  const duplicatedCollectionNodeId = collectionId.slice(0, -1) + parentCollectionsLength;

  duplicatedCollectionNode.instance.id = duplicatedCollectionNodeId;
  const nameAndLabel = duplicatedCollectionNode.instance.referenceInput.ui.name + " - Copy";

  duplicatedCollectionNode.instance.referenceInput.ui = {
    name: nameAndLabel,
    label: nameAndLabel,
  };

  function iterateThroughNodeInputs(nodes) {
    if (!nodes) {
      return;
    }

    nodes.forEach(node => {
      node.instance.id = node.instance.id.replace(collectionId, duplicatedCollectionNodeId);
      if (node.inputs) {
        iterateThroughNodeInputs(node.inputs);
      }
    });
  }

  if (duplicatedCollectionNode.inputs) {
    iterateThroughNodeInputs(duplicatedCollectionNode.inputs);
  }

  if (collectionParentNode) {
    collectionParentNode.inputs.push(duplicatedCollectionNode);
    resetCollectionIds(collectionParentNode);
    if (withHistory) {
      let currentState = deepCopy(collectionParentNode);
      HistoryStore.pushCollection(previousState, currentState);
    }
  }

  return duplicatedCollectionNode;
}

function addCollection(collectionId, withHistory = true) {
  const collectionParentNode = getDeepNodeById(collectionId);
  let previousState;
  if (withHistory) {
    previousState = deepCopy(collectionParentNode);
  }

  if (collectionParentNode) {
    const collectionParentNodeInstance = collectionParentNode.instance;
    const collectionParentNodeInputsReference = collectionParentNodeInstance.getReferenceInput();
    const collectionParentNodeInputsLength = collectionParentNode.inputs.length;
    const collectionParentNodeName = collectionParentNodeInputsReference.ui.name;
    // TODO Keep vigilance here because removing the force enabling collection can cause side effects

    const childCollectionId = collectionParentNodeInstance.getId() + "." + collectionParentNodeInputsLength;
    const childCollectionNode = {
      instance: createChildCollection(
        childCollectionId,
        collectionParentNodeName + " " + (collectionParentNodeInputsLength + 1)
      ),
    };

    const deepStore = generateCollectionDeepStore(collectionParentNodeInputsReference.inputs, childCollectionId);
    childCollectionNode.inputs = deepStore.store;
    collectionParentNode.inputs.push(childCollectionNode);

    if (deepStore.collectionsToAdd.length >= 1) {
      deepStore.collectionsToAdd.forEach(collection => {
        for (let i = 0; i < collection.count; i++) {
          addCollection(collection.id, withHistory);
        }
      });
    }
    resetCollectionIds(collectionParentNode);
    if (withHistory) {
      let currentState = deepCopy(collectionParentNode);
      HistoryStore.pushCollection(previousState, currentState);
    }
    return childCollectionNode.instance;
  }
  return undefined;
}

function restoreCollection(collectionNode) {
  const collectionId = collectionNode.instance.getId();
  const _collectionNode = getDeepNodeById(collectionId);
  if (!_collectionNode) {
    return;
  }

  _collectionNode.inputs = collectionNode.inputs;
  _collectionNode.instance = collectionNode.instance;
}

async function generateDeepStoreFromValues(jsonValues) {
  // Reset the store to default values
  initializeDeepStore();

  const assignationStore = {};

  function assignValue(id, value) {
    if (!assignationStore[id]) {
      assignationStore[id] = {};
    }
    assignationStore[id].value = value;
  }

  function assignMeta(id, meta) {
    if (!assignationStore[id]) {
      assignationStore[id] = {};
    }
    assignationStore[id].meta = meta;
  }

  function assign(stackId, stackValue) {
    if (stackId.endsWith(META_SUFFIX)) {
      const actualStackId = stackId.substring(0, stackId.length - META_SUFFIX.length);
      assignMeta(actualStackId, stackValue);
      return;
    }

    if (stackId.endsWith(BINARY_SUFFIX)) {
      const actualStackId = stackId.substring(0, stackId.length - BINARY_SUFFIX.length);
      assignValue(actualStackId, { [BINARY_SUFFIX.slice(1)]: stackValue });
    } else {
      assignValue(stackId, stackValue);
    }
  }

  function iterateThroughStack(stack, id) {
    for (const key in stack) {
      const stackId = id + "." + key;
      const stackValue = stack[key];

      if (isObject(stackValue) && !stackId.endsWith(META_SUFFIX)) {
        const instance = getDeepNodeById(stackId, true);
        if (instance) {
          getDeepNodeById(instance.getParentId(), true).setEnabled(true, true, false);
        }

        if (instance && instance.isFinal()) {
          assign(stackId, stackValue);
        } else {
          if (instance) {
            instance.setEnabled(true, true, false);
          }
          iterateThroughStack(stackValue, stackId);
        }
      } else if (isArray(stackValue)) {
        for (let i = 0; i < stackValue.length; i++) {
          const childCollectionId = stackId + "." + i;
          const childCollectionInstance = getDeepNodeById(childCollectionId, true);
          if (!childCollectionInstance) {
            addCollection(stackId, false);
          }
        }
        iterateThroughStack(stackValue, stackId);
      } else {
        assign(stackId, stackValue);
      }
    }
  }

  iterateThroughStack(jsonValues, References.ROOT_INPUT_ID);

  Debug.success("Import values has been processed and generated to following assignation store : ", assignationStore);

  for (const key in assignationStore) {
    const instance = getDeepNodeById(key, true);
    if (instance) {
      const { value, meta } = assignationStore[key];
      await instance.import(value, meta);
    } else {
      Debug.warn(
        "Can't assign value to input with key : '" +
          key +
          "' because its instance doesn't exists. It might be linked to a wrong template revision.",
        assignationStore
      );
    }
  }
  // Assigning the first unit we find according to the ratio.
  const ratio = jsonValues?.ratio;
  if (ratio) {
    const unitInputInstance = unitService.getCurrentUnitInputInstance();
    if (ratio === 1.7777) {
      return unitInputInstance?.setValue("header_ad", true, undefined, false);
    }
    const availableUnits = await unitService.getUnitsForSpecificRatio(ratio);
    if (availableUnits?.length) {
      return unitInputInstance?.setValue(availableUnits[0].technicalId, true, undefined, false);
    }
  }
}

export default {
  addCollection,
  moveCollection,
  duplicateCollection,
  deleteCollection,
  restoreCollection,
  initializeDeepStore,
  generateDeepStoreFromValues,
  getDeepNodeById,
  getDeepStore: () => DEEP_STORE,
  setCurrentTemplate: template => (CURRENT_TEMPLATE = template),
  getCurrentTemplate: () => CURRENT_TEMPLATE,
};
