import React from "react";
import { Select } from "@ogury/design-system";

const browserLanguage = "browserLanguage";
const Languages = [
  { label: "Browser language (default)", value: browserLanguage },
  { label: "French", value: "fr" },
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  { label: "Italian", value: "it" },
  { label: "German", value: "de" },
  { label: "Dutch", value: "nl" },
  { label: "Japanese", value: "ja" },
  { label: "Filipino", value: "fil" },
  { label: "Hindi", value: "hi" },
  { label: "Thai", value: "th" },
  { label: "Malay", value: "ms" },
  { label: "Chinese", value: "zh" },
  { label: "Indonesian", value: "id" },
  { label: "Danish", value: "da" },
  { label: "Finnish", value: "fi" },
  { label: "Norwegian", value: "no" },
  { label: "Portuguese", value: "pt" },
  { label: "Slovene", value: "sl" },
  { label: "Swedish", value: "sv" },
  { label: "Tamil", value: "ta" },
];

export default function LanguageSelector({ onChange = () => {} }) {
  function handleOnChangeLanguage(val) {
    if (val === browserLanguage) {
      window.builderLanguage = undefined;
    } else {
      window.builderLanguage = val;
    }
    onChange(val);
  }
  return (
    <Select
      data-testid="language-selector"
      defaultValue={browserLanguage}
      options={Languages}
      onChange={handleOnChangeLanguage}
    />
  );
}
