const signIn = {
  title: "Sign in",
  notification: {
    description: "Credentials error",
  },
};
const error = {
  undefinedError: "This field can not be empty",
  invalid: {
    URL: "The URL is invalid. Make sure URL is correct and try again.",
    URLDate: "The inputs URL does not contain valid data",
    corruptedBinary: "Could not read the file : the URL or the binary data is incorrect.",
    font: "Invalid font because its computed MIME type is '{{mimeType}}'",
    lottie: "Invalid Lottie because its computed MIME type is '{{mimeType}}'",
    image: "Invalid image because its computed MIME type is '{{mimeType}}'",
    file: "Invalid file because its computed MIME type is '{{mimeType}}'",
    lottieFile: "Invalid Lottie file.",
  },
  notSupported: {
    fontFormat: "The font format '{{format}}' is not supported and should one of the following formats: ",
    imageFormat: "The image format '{{format}}' is not supported and should one of the following formats: ",
    invalidData: "The image is corrupted or invalid",
  },
  unhandled: {
    fontMIMEType: "Unhandled font MIME type '{{mimeType}}'",
    imageMIMEType: "Unhandled image MIME type '{{mimeType}}'",
  },
  condition: {
    countSuffix: "Cannot apply the '{{countSuffix}}' condition on a non-collection input",
    contains: "Cannot apply a '{{contains}}' condition on a non-string input",
    match: "Cannot apply a '{{match}}' condition on a non-string input",
    operator: "Unhandled '{{operator}}' operator condition",
  },
  constraint: {
    fontWeight: "The font weight is above the constraint limit of {{maximumBytes}} bytes",
    lottieFile: "The Lottie file weight is above the constraint limit of {{maximumBytes}} bytes",
    numberMin: "The number is below the constraint limit of ",
    numberMax: "The number is above the constraint limit of ",
    imageRatio: "The image width-over-height ratio is different from the constraint limit of ",
    imageRatioMin: "The image width-over-height ratio is below the constraint limit of ",
    imageRatioMax: "The image width-over-height ratio is above the constraint limit of ",
    imageWeight: "The image weight is above the constraint limit of {{maximumBytes}} bytes",
    imagePixel: "The image number of pixels is below the constraint limit of {{minimumPixels}} pixels",
    charactersMin: "The number of characters is below the constraint limit of {{minimum}} characters",
    charactersMax: "The number of characters is above the constraint limit of {{maximum}} characters",
    videoWeightMax: "The maximum weight for the video should be set to ",
    videoDurationMax: "The maximum duration for the video should be set to ",
    videoDurationMin: "The minimum duration for the video should be set to ",
    videoWidthMin: "The minimum width for the video should be set to {{value}} pixels",
    videoWidthMax: "The maximum width for the video should be set to {{value}} pixels",
    videoRatio: "The video ratio for the video should be set to {{value}}",
    videoRatioMin: "The minimum ratio for the video should be set to {{value}}",
    videoRatioMax: "The maximum ratio for the video should be set to {{value}}",
  },
  export: {
    inputIdIssue: "Could not export the data related to the input with id '{{inputID}}'. Reason: '{{reason}}'",
  },
  saveExperience: {
    nameRequired: "The creative name cannot be empty",
    invalidLength: "The name cannot exceed 64 characters",
    extractData: "Could not extract properly the form data. Reason: '{{reason}}'",
    inputsProcessing: "{{inputsProcessingCount}} form inputs are still processing",
    inputsIssues: "Some of the form inputs require your attention",
  },
  showExperience: {
    message: "Could not show the creative",
  },
  compute: {
    blobType: "Could not read the blob with type '{{type}}'",
    blobMIMEType:
      "Could not determine the MIME type of the blob with type '{{type}}', because its header '{{header}}' is unknown",
    urlMIMEType: "Could not determine the MIME type of the asset with URL '{{url}}'. Reason: '{{reason}}'",
    invalidImage: "Invalid or unsupported image",
    invalidBinaryInput: "Could not fetch the input, please delete and re-upload the asset.",
  },
  download: {
    CORSRestriction: "Could not download the content at URL '{{url}}' because of a CORS restriction",
    unknownReason: "Could not download the content at URL '{{url}}' for an unknown reason",
    viaProxy: "Could not download via the proxy the content at URL '{{url}}'. Reason: '{{reason}}'",
    noSupportCORS: "Could not download the content at URL '{{url}}' because it does not support CORS",
    internetIssue: "Could not download the content at URL '{{url}}' because of an Internet connection issue",
  },
  technical: {
    computeInputs: "The 'computeInputs()' method must be implemented!",
  },
};
const inputs = {
  label: {
    font: "Font",
    formats: "Formats",
    weight: "Weight",
    lottie: "Lottie",
    value: "Value",
    ratio: "Ratio",
    pixels: "Pixels",
    characters: "Characters",
    width: "Width",
    height: "Height",
    euration: "Duration",
    URL: "URL",
    size: "Size",
    toURL: "Load image",
    dimensions: "Dimensions",
    rotation: "Rotation angle",
    x: "x",
    backgroundColor: "Background color",
  },
  properties: {
    px: "px",
    ratio: "ratio",
  },
  header: {
    constraints: "Constraints",
  },
  sampleText: {
    text: " The quick brown fox jumps over the lazy dog \n  THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG \n 0123456789",
  },
  error: {
    invalid: "Invalid",
    internalError: "Internal error: invalid input (probably an issue with a new type, not yet handled)!",
  },
};
const inputsUI = {
  tooltip: {
    imageOverlay: {
      imageFileControl: "Border highlight is a visual helper. It will not be processed while rendering the creative.",
    },
    actions: {
      edit: "Edit",
      delete: "Delete",
      download: "Download",
    },
  },
  text: {
    imageOverlay: {
      title: "Image",
      referenceMissing: "Overlay widget can't be displayed, you must select a reference image first.",
    },
  },
  button: {
    color: {
      cancel: "Cancel",
      confirm: "Confirm",
    },
    binary: {
      cancel: "Cancel",
      confirm: "Confirm",
    },
    font: {
      convert: "Convert",
    },
    imageOverlay: {
      changeImage: "Change image",
      cancel: "Cancel",
      positioningDisclaimer: "Hold SHIFT key while dragging the image if you want to snap it to frame border.",
    },
    sprite: {
      crop: "Crop",
      resize: "Resize",
      JPEG: "=>JPEG",
      quality: "Quality",
    },
    videoUrls: {
      cancel: "Cancel",
      fix: "Fix",
      confirm: "Confirm",
      change: "Change",
    },
    yes: "Yes",
    save: "Save",
    resize: "Resize",
    apply: "Apply",
    choose: "Choose",
  },
};
const notification = {
  reminder: {
    notSavedTitle: "Unsaved changes",
    notSaved: "You have unsaved changes. Are you sure you want to discard them and leave the builder?",
    discard: "Discard",
  },
  editImage: {
    title: "Edit image",
  },
  editFont: {
    title: "Edit font",
  },
  templateLoaded: "Template successfully loaded",
  duplicationDone: "The creative has been successfully duplicated.",
  saveDone: "The creative has been successfully saved.",

  importDone: "Data successfully imported",
  importFailed:
    "The file data is not a valid JSON or does not fit the current template. Please check the file integrity and the current template.",
  noResultsTitle: "No data to display",
  noResultsDescription: "There are not items to be displayed",
};
const accessibility = {
  validatingExperience: "Validating the creative...",
  savingExperience: "Saving the creative...",
  duplicatingExperience: "Duplicating the creative to ad-unit : {{unit}}",
  loadingInputs: "Downloading the inputs...",
  loadingTemplate: "Loading the template definition...",
  processingData: "Converting the data...",
  importingInputs: "Importing the inputs...",
};
const header = {
  fromTemplate: "From template",
  creativeWeightModalTitle: "Creative weight",
  duplicateModalTitle: "Save creative",
  button: {
    save: "Save",
    saveAndDuplicate: "Save & Duplicate",
    saveAndReload: "Save & Reload",
    saveTooltip: "Save (Ctrl + S)",
    saveTooltipMAC: "Save (⌘ + S)",
    undoTooltip: "Undo (Ctrl + Z)",
    undoTooltipMAC: "Undo (⌘ + Z)",
    redoTooltip: "Redo (Ctrl + Y)",
    redoTooltipMAC: "Redo (⌘ + ⇧ + Z)",
    import: "Import",
    export: "Export",
    test: "Test",
    poster: "Poster",
    showExperience: "Show Creative",
    reload: "Reload",
    creativeWeight: "Weight",
  },
  state: {
    published: "Published",
  },
  confirmSave: {
    title: "Save changes",
    content:
      "Are you sure you want to save the changes? This will update the published creative and may affect its performance.",
    save: "Save",
    cancel: "Cancel",
  },
  confirmImport: {
    title: "Confirm import",
    content: "Are you sure you want to import a new configuration? You won't be able to revert your changes.",
    import: "Confirm",
    cancel: "Cancel",
  },
  placeholders: {
    experienceName: "Untitled creative",
  },
};
const leftPanel = {
  PSDExplorer: {
    uploadText: "Drag and drop file here or Browse",
    currentFile: "Current file:",
    recentlyUploaded: "Recently uploaded:",
    yesterday: "Yesterday",
    loading: "Parsing PSD file",
  },
  collection: {
    countMaximumReached: "The maximum amount of items for this collection has been reached.",
  },
  contextualMenu: {
    button: {
      duplicate: "Duplicate",
      moveUp: "Move up",
      moveDown: "Move down",
      delete: "Delete",
    },
  },
  settings: "Settings",
  settingsTab: {
    units: "Units",
    pixels: "Pixels (px)",
    percentage: "Percentage (%)",
  },
  layers: "Layers",
  assets: "Assets",
};
const centerPanel = {};
const rightPanel = {
  saveToPreview: "Save to preview the creative",
  generatingPreview: "Generating the preview...",
  errors: {
    localPreviewHaveBinaryAssets:
      "Some assets could not be loaded. To see the preview, please load them manually first:",
    localPreviewHasGlobalError:
      "The preview can not be rendered yet, there might be empty assets in the layers. If the error is persisting please try the 'Reload' button.",
    goToInput: "Go to input",
    remotePreview: {
      warningStoreChangedTitle: "Preview outdated",
      warningStoreChanged: "The inputs of the creative have changed, please save and reload the preview.",
    },
  },
  score: "Scene",
  allScores: "All Scenes",
  track: "Chapter",
  allTracks: "All Chapters",
};
const components = {
  duplicateModal: {
    warning: "Save time and make your creative fits additional units.",
    alreadyExists: "Already exists in the multi ad-units creative.",
    ratioNotCompatible: "Creative's ratio not compatible.",
    unit: "Unit",
    optional: "Optional",
    current: "Current",
    recommended: "Recommended",
  },
  creativeWeight: {
    errorTitle: "Error",
    errorDescription:
      "The creative's weight could not be retrieved. Please attempt again later or reach out to support for additional assistance.",
    emptyImageInChart: "Hover the chart to see the asset",
    optimize: "Optimize",
    total: "Total : ",
    device: "Device",
    advancedSettings: "Advanced settings",
    showAssetsOnly: "Show assets only",
    seeInBuilder: "See in Builder",
    openUrl: "Open URL",
    assets: "Assets",
    others: "Others",
  },
  lottieOverlay: {
    apiModal: {
      title: "Download from LottieFiles",
      searchPlaceholder: "Search animations",
    },
    download: "Download",
  },
  picker: {
    or: "OR",
  },
  filePicker: {
    uploadText: "Drag and drop file here",
    uploadHintLotties: "Supported files : JSON. Maximum file size 4MB",
    uploadHintImages: "Supported files : PNG, JPG, JPEG, GIF, WEBP. Maximum file size 4MB",
    uploadHintVideos: "Supported files : MP4, WEBM. Maximum file size 50MB",
    uploadHintPsd: "Supported files : PSD.",
    uploadHintCsv: "Supported files : CSV. Maximum file size {{size}}KB",
  },
  OkCancelButtons: {
    cancel: "Cancel",
  },
  tabularInput: {
    supportedFiles: "Supported files: .csv. Maximum file size: {{size}}KB.",
    downloadTemplate: "Download template (.csv)",
    lastModified: "Last updated",
    database: "Database",
    errors: {
      invalidFile: "invalid file.",
      emptyFile:
        "The file is empty. Please make sure the file contains all the required data and try to upload it again..",
      emptyFileValues:
        "The file only contains headers. Please make sure the file contains all the required data and try to upload it again.",
      invalidFileSize: "invalid file size. Maximum allowed is {{size}} KB.",
      missingColumns: "Missing required columns: {{columns}}.",
      invalidType: 'invalid value for "{{column}}" in row {{row}}. Expected: "{{type}}".',
      invalidLanguageCode:
        'invalid value for "{{column}}" in row {{row}}. Language code should match with ISO 639-1 nomenclature.',
      invalidBooleanValue: 'invalid value for "{{column}}" in row {{row}}. Expected: TRUE or FALSE.',
      invalidIntegerMin: 'invalid value for "{{column}}" in row {{row}}. Expected: min {{min}}.',
      invalidIntegerMax: 'invalid value for "{{column}}" in row {{row}}. Expected: max {{max}}.',
      emptyValue: 'the value is empty for "{{column}}" in row {{row}}.',
      invalidLength: 'invalid value length for "{{column}}" in row {{row}}. Expected: min {{min}}, max {{max}}.',
      invalidColumns: "invalid number of columns in row {{row}}.",
      uploadFailed: "Import has failed:",
      tryAgain: "Please correct the errors and try to upload the file again.",
    },
    googleSpreadSheet: {
      help: "Please make sure the file is uploaded to the dedicated <0>shared Google Drive folder<1/></0>",
      placeholder: "e.g. https://docs.google.com/spreadsheets/d/...",
    },
  },
  filePickerV2: {
    or: "or",
    uploadText: "Drag and drop file here",
    browse: "Browse",
  },
  urlFileUpload: { upload: "Upload" },
  inputImage: {
    urlPlaceholder: "Image URL",
  },
};

const comment = {
  CTA: {
    typeAcceptableValues: {
      none: "No CTA is available.",
      surface: "The CTA is active over the whole creative frame surface.",
      icon: "The CTA is displayed as an icon button.",
      surfaceicon:
        "The CTA is displayed as an icon button but the CTA is also active over the whole creative frame surface.",
      button: "The CTA is displayed as a textual button.",
      surfacebutton:
        "The CTA is displayed as a textual button but the CTA is also active over the whole creative frame surface.",
    },
    computeInputs: {
      type: "Indicates how the CTA should be displayed.",
      actionUrl:
        "Defines the URL of the Call-To-Action (CTA), which will be opened when the end-user clicks / taps on the CTA button.",
      text: "Defines the button text, which will be displayed inside the button.",
      mode: "Indicates whether the click on Call-To-Action (CTA) should open a new browser window (<code>New Window</code>) or re-use the existing window (<code>Same Window</code>) for displaying the CTA URL.",
      color: "Defines the button text color.",
      backgroundColor: "Defines the button background color.",
      size: "Defines the button size.",
      theme: "Defines the button theme, which specifies the button background color.",
      icon: "Defines the button icon, which is displayed inside the button.",
      font: "Defines the button font, which specifies the font used to display the text.",
      buttonStyle: {
        ui: "Defines the button style.",
        rectangle: "The button corners are square with a 90° angle.",
        pill: "The button corners are rounded like a pill.",
      },
      stroke: "Defines the button stroke thickness, expressed in percentage of the font size (100 means 100%).",
      opacity: "Defines the button background opacity.",
      padding: {
        ui: "Defines the button paddings.",
        horizontal: "Defines the button horizontal padding, expressed in percentage of the text size (100 means 100%).",
      },
      vertical: "Defines the button vertical padding, expressed in percentage of the text size (100 means 100%).",
      position:
        "Defines the position of the CTA button center in comparison with the creative frame top-left edge.<br/>It is expressed in percentage of the creative frame width and height.",
    },
  },
  stripe: {
    computeInputs: {
      visible: "Indicates whether the story progression stripes should be displayed.",
      true: "Stripes are displayed to account for the progress within chapters.",
      false: "No stripes are displayed.",
      topOffsetPercentage:
        "Indicates where the story stripes should be positioned vertically within the creative frame, from its top edge. It is expressed in percentage of the creative frame height.",
      widthPercentage:
        "Indicates the width of the story stripes compared to the creative frame width. The stripes will always be centered horizontally. It is expressed in percentage of the creative frame width.",
      thicknessPercentage:
        "Indicates the height of the story stripes compared to the creative frame height. It is expressed in percentage of the creative frame height.",
      backgroundColor:
        "Specifies the stripes background color, which is used to colorize their background and define a transparency level.",
      foregroundColor:
        "Specifies the color of the story stripes, which is used to colorize their foreground and define a transparency level.",
    },
  },
};

export default {
  accessibility,
  signIn,
  error,
  inputs,
  inputsUI,
  notification,
  header,
  leftPanel,
  centerPanel,
  rightPanel,
  components,
  comment,
};
