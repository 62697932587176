import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FilePicker, IconNames, Icons, InputActions, InputWrapper, VideoPlayer } from "components";
import Api from "~/util/Api";
import { toStringWithBytes, toStringWithDimensions, toStringWithDuration, toStringWithInteger } from "~/util/Helpers";
import { computeFileNameFromUrl, fromBlobToDataUrl, META_SUFFIX, sanitizeFileName } from "~/util/InputsComputer";
import Model from "../../model/Model";
import { downloadBlob } from "~/util/Utils";
import style from "../InputSpriteUi/InputSpriteUi.module.scss";
import { Loader, Space, Typography } from "@ogury/design-system";
import PropTypes from "prop-types";

const VideoFixer = ({
  setInner,
  setVideo,
  errorMessage,
  videoElement,
  blob,
  dataUrl,
  minimumRatio,
  maximumRatio,
  minimumWidth,
  maximumWidth,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [t] = useTranslation();

  return (
    <>
      <VideoPlayer src={dataUrl} size="small" />
      <Typography.P2Regular>{errorMessage}</Typography.P2Regular>
      <InputActions>
        <Button
          type="secondary"
          style={{ marginRight: 10 }}
          onClick={async event => {
            event.preventDefault();
            setInner(undefined);
          }}
        >
          {t("inputsUI.button.videoUrls.cancel")}
        </Button>
        <Button
          style={{ marginRight: 10 }}
          loading={isProcessing}
          onClick={async () => {
            setIsProcessing(true);
            const resizedWidth = Math.max(
              minimumWidth === undefined ? Number.MIN_VALUE : minimumWidth,
              Math.min(maximumWidth === undefined ? Number.MAX_VALUE : maximumWidth, videoElement.videoWidth)
            );
            const resizedHeight =
              minimumRatio === undefined && maximumRatio === undefined
                ? videoElement.videoHeight
                : minimumRatio === undefined
                ? resizedWidth / maximumRatio
                : maximumRatio === undefined
                ? resizedWidth / minimumRatio
                : (2 * resizedWidth) / (minimumRatio + maximumRatio);
            const factor = resizedHeight <= videoElement.videoHeight ? 1 : videoElement.videoHeight / resizedHeight;
            const width = resizedWidth * factor;
            const height = resizedHeight * factor;
            const resizedBlob = await Api.resizeVideo(
              "webm",
              blob,
              Math.round(width),
              resizedWidth === minimumWidth || resizedWidth === maximumWidth ? undefined : Math.round(height)
            );
            setVideo(resizedBlob, width, height);
          }}
        >
          {t("inputsUI.button.videoUrls.fix")}
        </Button>
      </InputActions>
    </>
  );
};
VideoFixer.propTypes = {
  setInner: PropTypes.func.isRequired,
  setVideo: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  videoElement: PropTypes.node.isRequired,
  blob: PropTypes.object.isRequired,
  dataUrl: PropTypes.string.isRequired,
  minimumRatio: PropTypes.number.isRequired,
  maximumRatio: PropTypes.number.isRequired,
  minimumWidth: PropTypes.number.isRequired,
  maximumWidth: PropTypes.number.isRequired,
};

const InputVideoUrlsUi = ({ input }) => {
  const [isDeclaring, setIsDeclaring] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [rawVideo, setRawVideo] = useState(undefined);
  const [t] = useTranslation();

  const renderProperties = (url, durationInMilliseconds, width, height) => {
    return (
      <div className={style.propertiesContainer}>
        <Typography.P2Regular className={style.name}>{computeFileNameFromUrl(url, true)}</Typography.P2Regular>
        <div>
          <Typography.P2Regular>{toStringWithDuration(durationInMilliseconds)}</Typography.P2Regular>
          <Typography.P2Regular>, {toStringWithDimensions(width, height)}</Typography.P2Regular>
        </div>
      </div>
    );
  };

  const declareVideo = async (blob, fileName, durationInMilliseconds, width, height) => {
    setIsDeclaring(true);
    input.setProcessing(true);
    try {
      const result = await input.transcodeVideo(blob, fileName, durationInMilliseconds, width, height);
      input.setValue(result);
      setIsConfirmed(true);
      setIsChanging(false);
    } finally {
      input.setProcessing(false);
      setIsDeclaring(false);
    }
  };

  const renderPicker = () => {
    const { minimum: minimumRatio, maximum: maximumRatio } = input.getRatioConstraints();
    const { minimum: minimumWidth, maximum: maximumWidth } = input.getWidthConstraints();

    const setValidVideo = (fileName, blob, dataUrl, durationInMilliseconds, width, height) => {
      let name = sanitizeFileName(fileName);

      setRawVideo({
        blob,
        dataUrl,
        name,
        durationInMilliseconds,
        width,
        height,
      });
    };

    const renderInner = (setInner, errorMessage, videoElement, blob, dataUrl, fileName, durationInMilliseconds) => {
      setInner(
        <VideoFixer
          setInner={setInner}
          setVideo={async (blob, width, height) => {
            setValidVideo(fileName, blob, await fromBlobToDataUrl(blob), durationInMilliseconds, width, height);
          }}
          errorMessage={errorMessage}
          videoElement={videoElement}
          blob={blob}
          dataUrl={dataUrl}
          minimumRatio={minimumRatio}
          maximumRatio={maximumRatio}
          minimumWidth={minimumWidth}
          maximumWidth={maximumWidth}
        />
      );
    };
    renderInner.propTypes = {
      setInner: PropTypes.func.isRequired,
      errorMessage: PropTypes.string.isRequired,
      videoElement: PropTypes.node.isRequired,
      blob: PropTypes.object.isRequired,
      dataUrl: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      durationInMilliseconds: PropTypes.number.isRequired,
    };

    const formats = input.getFormatsConstraints();
    const mimeTypes = formats === undefined ? "video/*" : Model.formatsToMimeTypes(formats);

    // noinspection JSUnusedLocalSymbols
    return (
      <Space size="m" direction="vertical">
        <Loader isFetching={isProcessing}>
          <FilePicker
            input={input}
            fileExtensions={
              formats === undefined
                ? [".mp4", ".webm", ".mov", ".avi"]
                : Model.formatsToFileExtensions(formats).map(extension => {
                    return "." + extension;
                  })
            }
            mimeTypes={mimeTypes}
            hint={t("components.filePicker.uploadHintVideos")}
            processingMessage="Converting the video into another compatible format…"
            onFileChanged={async ({ blob, fileName, mimeType, dataUrl, base64Data, setInner }) => {
              setIsProcessing(true);
              // We check that the video is OK
              const weightInBytes = blob.size;
              const maximumBytes = input.getMaximumBytesConstraints();
              if (maximumBytes !== undefined && weightInBytes > maximumBytes) {
                throw new Error(t("error.constraint.videoWeightMax") + toStringWithBytes(maximumBytes));
              }

              const videoElement = document.createElement("video");
              const playVideo = async (blob, dataUrl) => {
                return new Promise(async (resolve, reject) => {
                  videoElement.autoplay = true;
                  videoElement.playsinline = false;
                  videoElement.muted = true;
                  videoElement.onloadeddata = () => {
                    videoElement.pause();

                    const durationInMilliseconds = videoElement.duration * 1000;
                    const { minimum: minimumDurationInMilliseconds, maximum: maximumDurationInMilliseconds } =
                      input.getDurationInMillisecondsConstraints();
                    if (
                      minimumDurationInMilliseconds !== undefined &&
                      durationInMilliseconds < minimumDurationInMilliseconds
                    ) {
                      return reject(
                        new Error(
                          t("error.constraint.videoDurationMin") + toStringWithDuration(minimumDurationInMilliseconds)
                        )
                      );
                    }
                    if (
                      maximumDurationInMilliseconds !== undefined &&
                      durationInMilliseconds > maximumDurationInMilliseconds
                    ) {
                      return reject(
                        new Error(
                          t("error.constraint.videoDurationMax") + toStringWithDuration(maximumDurationInMilliseconds)
                        )
                      );
                    }

                    const width = videoElement.videoWidth;
                    const height = videoElement.videoHeight;
                    const ratio = width / height;
                    if (minimumRatio !== undefined && ratio < minimumRatio) {
                      return reject(
                        new Error(
                          minimumRatio === maximumRatio
                            ? t("error.constraint.videoRatio", { value: ratio })
                            : t("error.constraint.videoRatioMin", { value: minimumRatio })
                        )
                      );
                    }
                    if (maximumRatio !== undefined && ratio > maximumRatio) {
                      return reject(
                        new Error(
                          minimumRatio === maximumRatio
                            ? t("error.constraint.videoRatio", { value: ratio })
                            : t("error.constraint.videoRatioMax", { value: maximumRatio })
                        )
                      );
                    }

                    if (minimumWidth !== undefined && videoElement.videoWidth < minimumWidth) {
                      return reject(
                        new Error(t("error.constraint.videoWidthMin", { value: toStringWithInteger(minimumWidth) }))
                      );
                    }
                    if (maximumWidth !== undefined && videoElement.videoWidth > maximumWidth) {
                      return reject(
                        new Error(t("error.constraint.videoWidthMax", { value: toStringWithInteger(maximumWidth) }))
                      );
                    }

                    setValidVideo(fileName, blob, dataUrl, durationInMilliseconds, width, height);
                    resolve();
                  };
                  videoElement.src = dataUrl;
                  try {
                    await videoElement.play();
                  } catch (error) {
                    error.retry = true;
                    reject(error);
                  }
                });
              };
              try {
                // We test with the provided video
                await playVideo(blob, dataUrl);
              } catch (error) {
                if (error.retry === true) {
                  // We do not use the "HTMLMediaElement.canPlayType()" method (https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/canPlayType), because it is not reliable
                  // Hence, we convert the video if the play failed
                  const format = mimeType === "video/mp4" ? "WEBM" : "MP4";
                  blob = await Api.convertVideo(format, blob);
                  dataUrl = await fromBlobToDataUrl(blob);
                  await playVideo(blob, dataUrl);
                } else {
                  throw error;
                }
              } finally {
                setIsProcessing(false);
              }
            }}
          />
          {isChanging === true && (
            <InputActions>
              <Button
                onClick={async () => {
                  setIsChanging(false);
                }}
              >
                {t("inputsUI.button.videoUrls.cancel")}
              </Button>
            </InputActions>
          )}
        </Loader>
      </Space>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <VideoPlayer src={rawVideo.dataUrl} />
        {renderProperties(rawVideo.name, rawVideo.durationInMilliseconds, rawVideo.width, rawVideo.height)}
        <InputActions>
          <Space size="s">
            <Button
              type="secondary"
              disabled={isDeclaring}
              onClick={async () => {
                setRawVideo(undefined);
              }}
            >
              {t("inputsUI.button.videoUrls.cancel")}
            </Button>
            <Button
              loading={isDeclaring}
              onClick={async () => {
                await declareVideo(
                  rawVideo.blob,
                  rawVideo.name,
                  rawVideo.durationInMilliseconds,
                  rawVideo.width,
                  rawVideo.height
                );
              }}
            >
              {t("inputsUI.button.videoUrls.confirm")}
            </Button>
            <Button
              type="secondary"
              disabled={isDeclaring}
              onClick={async () => {
                downloadBlob(rawVideo.blob, rawVideo.name);
              }}
            >
              <Icons name={IconNames.DownloadOutlined.type} />
            </Button>
          </Space>
        </InputActions>
      </>
    );
  };

  const renderInput = () => {
    const value = input.getValue();
    if (value?.source) {
      return (
        <>
          <VideoPlayer mp4Src={value.source} />
          {renderProperties(
            value["source" + META_SUFFIX]?.fileName,
            value["source" + META_SUFFIX]?.durationInMilliseconds,
            value["source" + META_SUFFIX]?.width,
            value["source" + META_SUFFIX]?.height
          )}
          <InputActions>
            <Button
              onClick={async () => {
                setRawVideo(undefined);
                setIsChanging(true);
                setIsConfirmed(false);
              }}
            >
              {t("inputsUI.button.videoUrls.change")}
            </Button>
          </InputActions>
        </>
      );
    }
  };

  return (
    <InputWrapper input={input}>
      {input.getValue() !== undefined && isChanging === false
        ? renderInput()
        : rawVideo !== undefined && (isChanging === false || isConfirmed === false)
        ? renderConfirmation()
        : renderPicker()}
    </InputWrapper>
  );
};

InputVideoUrlsUi.propTypes = {
  input: PropTypes.object.isRequired,
};

export default InputVideoUrlsUi;
