import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./Preview.module.scss";
import ResizeObserver from "resize-observer-polyfill";
import { useTranslation } from "react-i18next";
import { Button, Radio } from "@ogury/design-system";
import { debounce } from "~/util/Utils";
import { LanguageSelector } from "../index";
import { Loader } from "~/components";
import { LocalPreview, RemotePreview } from "./components";
import useStores from "~/hooks/useStores";

const RELOAD_DEBOUNCE_MILLISECONDS = 200;

const MODES = {
  LOCAL: "Story",
  REMOTE: "Scroll",
};

export default function Preview({ templateUrl, afterSaveSeed, onReloadAndSave }) {
  const [display, setDisplay] = useState(true);
  const [containerWidth, setContainerWidth] = useState(300);
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(MODES.LOCAL);
  const { uiStore } = useStores();
  const initialized = useRef(null);
  const [warningStoreChanged, setWarningStoreChanged] = useState(false);

  function handleOnRefreshComponent() {
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, RELOAD_DEBOUNCE_MILLISECONDS);
  }

  useEffect(() => {
    if (!display) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [display]);

  const cbRef = useRef();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentRect.height >= 400) {
          // Arbitrary value to stop reloading the preview if the container height is too small. This causes a kind of infinite loop
          setContainerWidth(Math.round(entry.contentRect.width) - 20);
          reload(); // need to reload everytime even for height change
        }
      }
    });

    resizeObserver.observe(cbRef.current);
    if (mode === MODES.REMOTE) {
      resizeObserver.disconnect();
    }
  }, [mode]);

  const reload = useCallback(debounce(handleOnRefreshComponent, RELOAD_DEBOUNCE_MILLISECONDS), []);

  useEffect(() => {
    const currentUiStore = JSON.stringify(uiStore);
    if (!initialized.current) {
      initialized.current = currentUiStore;
      return;
    }
    setWarningStoreChanged(initialized.current !== currentUiStore);
  }, [uiStore, initialized]);

  useEffect(() => {
    if (afterSaveSeed) {
      setWarningStoreChanged(false);
      initialized.current = JSON.stringify(uiStore);
      reload();
    }
  }, [afterSaveSeed]);

  return (
    <div className={style.iframeAndLanguageContainer} data-testid="preview-container">
      <div className={style.languageContainer} data-testid="language-and-reload-container">
        <Radio.Group value={mode} onChange={setMode}>
          <Radio.Button value={MODES.LOCAL}>{MODES.LOCAL}</Radio.Button>
          <Radio.Button value={MODES.REMOTE}>{MODES.REMOTE}</Radio.Button>
        </Radio.Group>
        <LanguageSelector onChange={handleOnRefreshComponent} />
        <Button type="secondary" onClick={handleOnRefreshComponent}>
          {t("header.button.reload")}
        </Button>
      </div>
      <div ref={cbRef} className={style.renderContainer}>
        {loading && <Loader overlay text={t("rightPanel.generatingPreview")} />}
        {display && mode === MODES.LOCAL && <LocalPreview templateUrl={templateUrl} containerWidth={containerWidth} />}
        {display && mode === MODES.REMOTE && (
          <RemotePreview
            warningStoreChanged={warningStoreChanged}
            seed={afterSaveSeed}
            onReloadAndSave={onReloadAndSave}
          />
        )}
      </div>
    </div>
  );
}
