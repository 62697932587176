import { useEffect, useState } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";

import AppProvider from "context/AppProvider";

import Layout from "app/layout/Layout";
import { Loader } from "components";
import { ProtectedRoute } from "~/util/ProtectedRoute";
import { Signin } from "app/public";
import Api from "~/util/Api";
import "i18n/i18n";
import Debug from "./util/Debug";

export default function App() {
  const [appInitialized, setAppInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      await Api.initialize();
      setAppInitialized(true);
      Debug.logAppConstants();
    })();
  }, []);

  return (
    <AppProvider>
      {appInitialized === false ? (
        <Loader overlay text="Initializing…" />
      ) : (
        <HashRouter>
          <Switch>
            <Route exact path="/signin" component={Signin} />
            <ProtectedRoute exact path="/" component={Layout} />
            <ProtectedRoute exact path="/fromExperience/:fromExperienceId" component={Layout} />
            <ProtectedRoute exact path="/experience/:experienceId" component={Layout} />
            <ProtectedRoute path="/template/:templateId/:encodedInputsFileUrl?" component={Layout} />
            <Route path="/*" render={() => <Redirect to="/" />} />
          </Switch>
        </HashRouter>
      )}
    </AppProvider>
  );
}
