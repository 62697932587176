const units = [
  {
    customerId: "5652041317744640",
    id: "5741222287114240",
    technicalId: "footer_ad",
    name: "Footer",
    comment: "The Ogury Footer",
    ratio: 3.2,
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 2,
      precisionFactor: 10,
    },
    properties: [
      {
        ratio: 3.2,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 4.4,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 13.64,
        isDefault: true,
      },
      {
        ratio: 1.7777,
        containerPolicy: "fit",
        isDefault: false,
      },
    ],
    tagIds: ["5632078242643968"],
  },
  {
    customerId: "5652041317744640",
    id: "5696509622353920",
    technicalId: "header_ad",
    name: "Header",
    comment: "The Ogury Header",
    ratio: 1.7777,
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 2,
      precisionFactor: 10,
    },
    properties: [
      {
        ratio: 4.4,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 29.9,
        isDefault: true,
      },
      {
        ratio: 1.7777,
        containerPolicy: "fit",
        isDefault: false,
      },
    ],
    tagIds: ["5740197970968576"],
  },
  {
    customerId: "5652041317744640",
    id: "5662857052028928",
    technicalId: "interstitial",
    name: "Interstitial",
    comment: "The Ogury Interstitial",
    ratio: 0.6,
    matchingRuleRatio: {
      minimumMultiplier: 0.6,
      maximumMultiplier: 1.4,
      precisionFactor: 10,
    },
    properties: [
      {
        ratio: 0.6,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 0.8,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 21.9,
        isDefault: true,
      },
    ],
    tagIds: ["5635746950021120"],
  },
  {
    customerId: "5652041317744640",
    id: "6251637072986112",
    technicalId: "overlay_thumbnail",
    name: "Thumbnail",
    comment: "The Ogury Thumbnail",
    ratio: 1.3333,
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 1.2,
      precisionFactor: 10,
    },
    properties: [
      {
        ratio: 1.3333,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    tagIds: ["5201172092682240"],
  },
];

export default units;
