import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert, Button, IconNames, Icons, Switch, Tooltip } from "components";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import style from "./InputHeader.module.scss";
import { theme, Typography } from "@ogury/design-system";

const InputHeader = ({ input, showConstraints = false, canBeDetached, onDetach }) => {
  const [t] = useTranslation();
  const ui = input.getUi();
  const label = ui[SCHEMA_INPUTS_FIELDS.UiLabel];
  const comment = ui[SCHEMA_INPUTS_FIELDS.UiComment];
  const showError = input.isShowError();
  const errors = input.getErrors();
  const isOptional = input.isOptional();
  const hasParent = input.getId().split(".")?.length > 1;

  let renderedConstraints = null;
  if (isOptional === true && showConstraints === true) {
    renderedConstraints = input.renderConstraints();
  }

  return (
    <>
      {/* {icon !== undefined && <InputIcon icon={icon} square />}*/}
      <div className={style.headerText}>
        <div className={style.titleContainer}>
          <div className={style.title}>
            {isOptional === true && (
              <div>
                <Switch
                  checked={input.isEnabled()}
                  onChange={value => {
                    input.setEnabled(value);
                  }}
                />
              </div>
            )}
            {hasParent ? (
              <Typography.P2Strong
                style={{
                  color: theme.colors.content.secondary,
                }}
              >
                {label}
              </Typography.P2Strong>
            ) : (
              <Typography.P2Strong>{label}</Typography.P2Strong>
            )}
            {comment !== undefined && <Tooltip content={<div dangerouslySetInnerHTML={{ __html: comment }} />} />}
          </div>
          <div>
            {canBeDetached && (
              <Button type="tertiary" size="small" onClick={onDetach} style={{ marginBottom: 10 }}>
                <Icons name={IconNames.ExpandAltOutlined.type} />
              </Button>
            )}
          </div>
        </div>
      </div>

      {renderedConstraints !== null && (
        <>
          <Typography.P2Regular
            style={{
              color: theme.colors.content.subdued,
            }}
          >
            {t("inputs.header.constraints")}:
          </Typography.P2Regular>
          <Typography.P2Regular>{renderedConstraints}</Typography.P2Regular>
        </>
      )}

      {showError &&
        errors.length > 0 &&
        // We only display the first error
        errors.slice(0, 1).map(error => {
          return (
            <div key={error.message} className={style.alertContainer}>
              <Alert showIcon type="error" message={error.message} />
            </div>
          );
        })}
    </>
  );
};

InputHeader.propTypes = {
  input: PropTypes.object.isRequired,
  showErrors: PropTypes.bool,
  showConstraints: PropTypes.bool,
};

export default InputHeader;
