import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Loader, FileInput } from "..";
import { fromBlobToMimeType, sanitizeFileName } from "~/util/InputsComputer";
import { theme, Typography } from "@ogury/design-system";

const FilePicker = ({
  fileExtensions,
  mimeTypes,
  processingMessage,
  onFileChanged,
  onError,
  isButton = false,
  label,
  hint,
  sanitizeFilename = false,
}) => {
  const [message, setMessage] = useState();
  const [inner, setInner] = useState();
  const [error, setError] = useState();
  const [t] = useTranslation();

  const onChanged = useCallback(
    async ({ blob, fileName, dataUrl }) => {
      setError(undefined);
      setInner(undefined);
      setMessage(processingMessage !== undefined ? processingMessage : "Processing…");
      let mimeType;
      try {
        mimeType = await fromBlobToMimeType(blob, true);

        if (!mimeTypes.includes(mimeType)) {
          setError(t("error.invalid.file", { mimeType }));
          setMessage(undefined);
          return;
        }
      } catch (error) {
        setError(error.message);
        setMessage(undefined);
        return;
      }

      if (sanitizeFilename) {
        fileName = sanitizeFileName(fileName);
      }
      try {
        await onFileChanged({
          blob,
          fileName,
          mimeType,
          dataUrl,
          base64Data: dataUrl.split(";")[1].split(",")[1],
          setInner: theInner => {
            if (theInner === undefined) {
              setError(undefined);
              setMessage(undefined);
            }
            setInner(theInner);
          },
        });
      } catch (error) {
        onError(error.message);
        setError(error.message);
        setMessage(undefined);
      }
    },
    [setMessage, setError, setInner]
  );

  const renderInner = useCallback(() => {
    if (inner?.props.errorMessage) {
      setInner(undefined);
      setMessage(undefined);
      setError(inner.props.errorMessage);
      return null;
    }

    return (
      <>
        {inner !== undefined ? (
          <Typography.P2Regular>{inner}</Typography.P2Regular>
        ) : message !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader>
              <Typography.P2Regular style={{ marginRight: 10 }}>{message}</Typography.P2Regular>
            </Loader>
          </div>
        ) : (
          <Typography.P2Regular>{label ? label : t("components.filePicker.uploadText")}</Typography.P2Regular>
        )}
      </>
    );
  }, [message, inner]);

  const disabled = message !== undefined || inner !== undefined;
  return (
    <>
      {error !== undefined && (
        <div>
          <Typography.P2Regular style={{ color: theme.colors.destructive.content }}>{error}</Typography.P2Regular>
        </div>
      )}
      <FileInput
        hint={hint}
        fileExtensions={fileExtensions}
        isButton={isButton}
        mimeTypes={mimeTypes}
        readDataUrl
        disabled={disabled}
        className={"drag-n-drop-zone" + (disabled === true ? " disabled" : "")}
        onProcessing={() => {
          setError(undefined);
          setMessage(processingMessage !== undefined ? processingMessage : "Processing…");
        }}
        onError={error => {
          setMessage(undefined);
          setError(error.message);
        }}
        onChanged={onChanged}
      >
        {renderInner()}
      </FileInput>
    </>
  );
};

FilePicker.propTypes = {
  fileExtensions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mimeTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  processingMessage: PropTypes.string,
  onFileChanged: PropTypes.func.isRequired,
  isButton: PropTypes.bool,
  sanitizeFilename: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  onError: PropTypes.func,
};

export default FilePicker;
