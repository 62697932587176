import {
  Alert,
  Button,
  Checkbox,
  Form,
  Modal,
  ModalClosableFooter,
  Space,
  Tag,
  Typography,
} from "@ogury/design-system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import experienceService from "~/services/ExperienceService";
import { default as UnitService, default as unitService } from "~/services/UnitService";
import style from "./DuplicateExperienceModal.module.scss";

export default function DuplicateExperienceModal({ open, onSave, onClose }) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [unitsOptions, setUnitsoptions] = useState([]);
  const [filteredUnitsOptions, setFilteredUnitsOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const units = await unitService.getUnits();
      setUnitsoptions(units);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (open) {
        const availableAdUnits = await experienceService.getAvailableUnitsForDuplication();
        const currentUnitTechnicalId = UnitService.getCurrentUnitTechnicalId();

        setFilteredUnitsOptions(
          unitsOptions
            .filter(
              unit =>
                availableAdUnits.some(availableUnit => availableUnit.technicalId === unit.technicalId) ||
                unit.technicalId === currentUnitTechnicalId
            )
            .map(unit => {
              if (unit.technicalId === currentUnitTechnicalId) {
                unit.isCurrentUnit = true;
              }
              return unit;
            })
            .sort(unit => (unit.isCurrentUnit ? -1 : 1))
        );
        form.resetFields();
        const initialValues = {
          unitsTechnicalIds: [...[currentUnitTechnicalId]],
        };
        form.setFieldsValue(initialValues);
      }
    })();
  }, [form, open, unitsOptions]);

  function handleOnSave() {
    setLoading(true);
    const { unitsTechnicalIds } = form.getFieldsValue();
    const currentUnitTechnicalId = unitService.getCurrentUnitTechnicalId();
    const unitsToDuplicate = unitsTechnicalIds.filter(technicalId => currentUnitTechnicalId !== technicalId);
    onSave(unitsToDuplicate);
    setLoading(false);
  }

  return (
    <Modal
      destroyOnClose
      title={t("header.duplicateModalTitle")}
      open={open}
      onCancel={onClose}
      footer={
        <ModalClosableFooter
          buttonLabel={t("components.OkCancelButtons.cancel")}
          actions={
            <Button loading={loading} onClick={handleOnSave}>
              {t("inputsUI.button.save")}
            </Button>
          }
        />
      }
    >
      <Space direction="vertical" size="xs" style={{ width: "100%" }}>
        <Alert showIcon closable={false} type="info" description={t("components.duplicateModal.warning")} />
        <Form form={form}>
          <div className={style.unit}>
            <Typography.P2Strong>{t("components.duplicateModal.unit")}</Typography.P2Strong>{" "}
            <Typography.P2Regular>({t("components.duplicateModal.optional")})</Typography.P2Regular>
          </div>
          <Form.Item name="unitsTechnicalIds">
            <Checkbox.Group className={style.form}>
              {filteredUnitsOptions?.map(unit => (
                <div className={style.item}>
                  <Checkbox disabled={unit.isCurrentUnit} value={unit.technicalId}>
                    {unit.name}
                  </Checkbox>
                  {unit.isCurrentUnit ? (
                    <Tag color="success">{t("components.duplicateModal.current")}</Tag>
                  ) : (
                    <Tag color="processing">{t("components.duplicateModal.recommended")}</Tag>
                  )}
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
}
