import React, { useMemo } from "react";
import { InputImage, InputWrapper } from "~/components";
import { Space } from "@ogury/design-system";
import { loadImage } from "~/util/Image";
import { META_SUFFIX } from "~/util/InputsComputer";
import InteractiveArea from "../../../../components/InteractiveArea/InteractiveArea";
import { KonvaElement } from "components";
import References from "~/util/References";
import PropTypes from "prop-types";

const InputImageOverlayUi = ({ input }) => {
  const inputData = useMemo(() => {
    if (input.getValue()) {
      const value = input.getValue();
      const meta = value?.["image" + META_SUFFIX];
      return {
        value,
        meta,
      };
    }
    return undefined;
  }, [input.getValue()]);

  const handleOnChanged = async imageValue => {
    const imageMeta = await loadImage(imageValue);
    input.setValue({
      image: imageValue,
      rotation: 0,
      size: 0,
      x: 0,
      y: 0,
      ["image" + META_SUFFIX]: {
        width: imageMeta.width,
        height: imageMeta.height,
      },
    });
  };

  const handleOnDelete = () => {
    input.clearValue();
  };

  function handleOnInteractiveAreaChange(values) {
    input.setValue(values);
  }
  return (
    <InputWrapper input={input}>
      <Space size="s" direction="vertical">
        <InputImage
          inputValue={inputData?.value?.image}
          inputName={input.getFilename()}
          onValueChanged={handleOnChanged}
          onDelete={handleOnDelete}
          formats={input.getFormatsConstraints()}
        />
        {inputData?.value?.image && (
          <InteractiveArea
            onChange={handleOnInteractiveAreaChange}
            constraintsReferenceInput={input.getConstraintsOnInputPathReferenceInstance()}
            inputData={inputData}
          >
            <KonvaElement type={References.KONVA_ELEMENT_TYPE_IMAGE} url={inputData?.value?.image} />
          </InteractiveArea>
        )}
      </Space>
    </InputWrapper>
  );
};

export default InputImageOverlayUi;

InputImageOverlayUi.propTypes = {
  input: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    getConstraintsOnInputPathReferenceInstance: PropTypes.func.isRequired,
    resetErrors: PropTypes.func.isRequired,
    clearValue: PropTypes.func.isRequired,
  }).isRequired,
};
