import Core from "~/services/Core";
import Api from "~/util/Api";
import { USE_STUBS } from "~/util/Constant";

export const syntheticUnitId = "synthetic-unit";

let UNITS;

const AD_UNIT_INTERSTITIAL = "interstitial";
const AD_UNIT_IN_ARTICLE_MPU = "in_article-300x250";

const templateConfig = {
  brandspotlight: {
    technicalIds: [AD_UNIT_INTERSTITIAL],
  },
  interactivepuzzle: {
    technicalIds: [AD_UNIT_INTERSTITIAL],
  },
  "coresurvey-mpu": {
    technicalIds: [AD_UNIT_IN_ARTICLE_MPU],
    hidden: true,
  },
  coresurvey: {
    technicalIds: [AD_UNIT_INTERSTITIAL],
    hidden: true,
  },
};

async function getUnits() {
  if (USE_STUBS) {
    return require("util/stubs/model/units").default;
  }
  if (!UNITS) {
    UNITS = await Api.getUnits();
  }
  return UNITS;
}

async function getUnitsTemplateDefinition(templateId) {
  const units = await getUnits();
  const config = templateConfig[templateId] || {};

  const acceptableValues = units
    .filter(unit => {
      return config.technicalIds ? config.technicalIds.includes(unit.technicalId) : true;
    })
    .map(unit => ({
      value: unit.technicalId,
      label: unit.name,
    }));

  const defaultValue = acceptableValues[0].value;

  return {
    id: syntheticUnitId,
    type: "string",
    ui: {
      name: "Ad Unit",
      label: "Ad Unit",
    },
    semantics: {
      kind: "synthetic",
    },
    acceptableValues,
    style: {
      layout: "combo",
      orientation: "horizontal",
    },
    default: defaultValue,
    ...(config.hidden ? { conditions: { hidden: true } } : {}),
  };
}

async function getAvailableRatios(unitTechnicalId) {
  const units = await getUnits();
  const ratiosPerUnit = units
    ?.find(unit => unit.technicalId === unitTechnicalId)
    ?.properties.map(property => ({ value: property.ratio, isDefault: property.isDefault }));
  return ratiosPerUnit || [];
}

function getCurrentUnitInputInstance() {
  return Core.getDeepNodeById("root." + syntheticUnitId, true);
}

function getCurrentUnitTechnicalId() {
  return getCurrentUnitInputInstance()?.getValue();
}

async function filterRatioOptions(options) {
  const currentUnitTechnicalId = getCurrentUnitTechnicalId();
  if (currentUnitTechnicalId) {
    const ratiosAvailables = await getAvailableRatios(currentUnitTechnicalId);
    const matchedOptions = [];

    const defaultRatio = ratiosAvailables.find(ratio => ratio.isDefault);
    const defaultOption = options.find(option => option.value === defaultRatio?.value);
    if (defaultRatio && defaultOption) {
      matchedOptions.push(defaultOption);
    }
    ratiosAvailables
      .filter(ratio => !ratio.isDefault)
      .forEach(ratio => {
        const matchedOption = options.find(option => option.value === ratio.value);
        if (matchedOption) {
          matchedOptions.push(matchedOption);
        }
      });
    return matchedOptions;
  }
  return options;
}

async function getUnitsForSpecificRatio(ratio) {
  const units = await getUnits();
  return units.filter(unit => unit.properties.some(property => property.ratio === ratio));
}

function getDangerIconSizeByAdUnitName(adUnitName) {
  if (adUnitName.startsWith("banner")) {
    return 0;
  }
  // Keep this function to add some specificities to a potential adUnit
  return 48;
}

async function getDangerZonesProperties(ratio) {
  const currentUnitTechnicalId = getCurrentUnitTechnicalId();

  if (currentUnitTechnicalId) {
    const units = await getUnits();
    const unitProperty = units
      ?.find(unit => unit.technicalId === currentUnitTechnicalId)
      ?.properties?.find(property => property.ratio === ratio);
    if (unitProperty) {
      switch (unitProperty.containerPolicy) {
        case "fitHeight":
          return {
            direction: "vertical",
            size: (unitProperty.dangerZonesSizePercentage || 0) + "%",
          };
        default:
          return undefined;
      }
    }
  }
  return undefined;
}

export default {
  filterRatioOptions,
  getDangerZonesProperties,
  getDangerIconSizeByAdUnitName,
  getUnitsTemplateDefinition,
  getCurrentUnitTechnicalId,
  getCurrentUnitInputInstance,
  getUnitsForSpecificRatio,
  getUnits,
};
